import React from 'react';
import { H5_URL } from '@/actions';
import './index.scss';

const IndexTitle = ({showmodal}) => (
  <div className="index-title">
    <div className="desc">河南省文化旅游投资集团旗下中视新科公司</div>
    <h1 className="h1">河南首家国资数字艺术规范化电商平台</h1>
    <div className="btn" onClick={() => showmodal(H5_URL)}>我们的故事</div>
  </div>
)

export default IndexTitle;