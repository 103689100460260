import React from 'react';
import board from '@/assets/board-illu.png';
import './index.scss';

const BOARD = {
  title: '万物有数数藏平台正式上线！',
  main: '寻万物定数之规律，悟天地无穷之奥妙。万物有数数字藏品平台今日正式上线！\n万物有数是河南首家国资数字艺术规范化电商平台，致力于打造具有国际影响力的数字IP运营商。未来，结合数字应用新空间，将数字藏品的展示和应用打通，实现线上线下权益联动，打造全新用户交互体验，充分扩充藏家想象空间，发掘历史文化价值、探索数字领域应用、开创华夏文明元宇宙新起点。',
  time: '2022年7月25日'
}

const IndexBoard = () => (
  <div className="index-board" id="huodong">
    <img className="index-board-pic" src={board} alt="banner" />
    <div className="index-board-content">
      <div className="index-board-content-title">{BOARD.title}</div>
      <div className="index-board-content-desc">{BOARD.main}</div>
      <div className="index-board-content-time">{BOARD.time}</div>
    </div>
  </div>
)

export default IndexBoard;