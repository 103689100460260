import React from 'react';
import qs_j from '@/assets/qs_j.png';
import qs_ft from '@/assets/qs_ft.png';
import qs_szy from '@/assets/qs_szy.png';
import zw_cr from '@/assets/zw_cr.png';
import zw_sx from '@/assets/zw_sx.png';
import zw_qy from '@/assets/zw_qy.png';
import zw_ld from '@/assets/zw_ld.png';
import zw_kd from '@/assets/zw_kd.png';
import avatar_fmr from '@/assets/avatar_fmr.png';
import avatar_yxzm from '@/assets/avatar_yxzm.png';
import zhidi_ewm from '@/assets/zhidi_ewm.png';
import './index.scss';

const GOODS = [{
  id: 1,
  title: '启世·聚',
  price: '99',
  cnt: '1000',
  img: qs_j,
  author: '飞梦人',
  authorAvatar: avatar_fmr,
  link: 'https://h5.alluknows.com/collection/CL31909452605165568',
}, {
  id: 2,
  title: '启世·沸腾',
  price: '99',
  cnt: '1000',
  img: qs_ft,
  author: '飞梦人',
  authorAvatar: avatar_fmr,
  link: 'https://h5.alluknows.com/collection/CL31909260959027200',
}, {
  id: 3,
  title: '启世·神之眼',
  price: '99',
  cnt: '600',
  img: qs_szy,
  author: '飞梦人',
  authorAvatar: avatar_fmr,
  link: 'https://h5.alluknows.com/collection/CL31909751151529984',
}, {
  id: 4,
  title: '造物·春日',
  price: '49.9',
  cnt: '1500',
  img: zw_cr,
  author: '有仙则名',
  authorAvatar: avatar_yxzm,
  link: 'https://h5.alluknows.com/collection/CL31907457651576832',
}, {
  id: 5,
  title: '造物·盛夏',
  price: '49.9',
  cnt: '1500',
  img: zw_sx,
  author: '有仙则名',
  authorAvatar: avatar_yxzm,
  link: 'https://h5.alluknows.com/collection/CL31907759171702784',
}, {
  id: 6,
  title: '造物·秋韵',
  price: '49',
  cnt: '1500',
  img: zw_qy,
  author: '有仙则名',
  authorAvatar: avatar_yxzm,
  link: 'https://h5.alluknows.com/collection/CL31908051187535872',
}, {
  id: 7,
  title: '造物·凛冬',
  price: '49',
  cnt: '1500',
  img: zw_ld,
  author: '有仙则名',
  authorAvatar: avatar_yxzm,
  link: 'https://h5.alluknows.com/collection/CL31908225129517056',
}, {
  id: 8,
  title: '造物·垦地',
  price: '49',
  cnt: '1500',
  img: zw_kd,
  author: '有仙则名',
  authorAvatar: avatar_yxzm,
  link: 'https://h5.alluknows.com/collection/CL31908696359571456',
}];

const IndexGoods = ({showmodal}) => (
  <div className="index-goods" id="zhidi">
    <div className="title">万物之地</div>
    <div className="list">
      {
        GOODS.map(item => (
          <div className="card" key={item.id}>
            <img className="card-img" src={item.img} onClick={() => showmodal(item.link)} alt="collection" />
            <div className="card-title">
              <div className="card-title-name">{item.title}</div>
              <img className="card-title-erweima" src={zhidi_ewm} onClick={() => showmodal(item.link)} alt="collection-erweima" />
            </div>
            <div className="card-num">
              <div className="card-num-price">￥{item.price}</div>
              <div className="card-num-cnt">限量：{item.cnt}份</div>
            </div>
            <div className="card-author">
              <img className="card-author-avatar" src={item.authorAvatar} alt="avatar" />
              <div className="card-author-name">{item.author}</div>
            </div>
          </div>
        ))
      }
    </div>
  </div>
)

export default IndexGoods;