import React, { useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { IndexHeader, IndexTitle, IndexBanner, IndexGoods, IndexOriginality, IndexBoard, IndexAbout, IndexEnergize, IndexFooter } from './components';
import { H5_URL } from '@/actions';
import './App.scss';
import { Modal } from 'antd';
import {
  CloseOutlined
} from '@ant-design/icons';


function App() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [url, setUrl] = useState(H5_URL);
  const showModal = (url) => {
    setUrl(url);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="index">
      <IndexHeader />
      <div className="container">
        <IndexTitle showmodal={showModal} />
        <IndexBanner showmodal={showModal} />
        <IndexGoods showmodal={showModal} />
      </div>
      <IndexOriginality />
      <IndexBoard />
      <IndexAbout />
      <IndexEnergize />
      <IndexFooter />
      <Modal visible={isModalVisible} onCancel={handleCancel} footer={null} width={450} bodyStyle={{padding: 0}} closable={false}>
        <div className="explore-modal" >
          <div className="explore-modal-title">使用微信扫一扫</div>
          <CloseOutlined  className="explore-modal-icon" onClick={handleCancel} />
          <div className='explore-modal-content'>
            <div className='explore-modal-content-img'>
              <QRCodeSVG value={'https://h5.alluknows.com/'} size="164" />
            </div>
            <div>扫码购买藏品</div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default App;
