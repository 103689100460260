import React from 'react';
import logo from '@/assets/logo.png';
import ewm_kf from '@/assets/ewm_kf.png';
import ewm_gzh from '@/assets/ewm_gzh.png';
import ewm_gwwb from '@/assets/ewm_gwwb.png';
import './index.scss';

const ERWEIMA = [{
  title: '扫码添加客服',
  erweima: ewm_kf,
}, {
  title: '官方公众号',
  erweima: ewm_gzh,
}, {
  title: '官方微博',
  erweima: ewm_gwwb,
}];

const IndexFooter = () => (
  <div className="index-footer">
    <div className="index-footer-content">
      <div className="index-footer-content-left">
        <img className="index-footer-content-left-logo" src={logo} alt="footer" />
        <div className="index-footer-content-left-title">欢迎来到万物有数</div>
        <div className="index-footer-content-left-desc">若您和您的团队想发行推⼴数字藏品，请联系我们</div>
      </div>
      <div className="index-footer-content-right">
        <div className="index-footer-content-right-erweima">
          {
            ERWEIMA.map(item => (
              <div className="erweima-item">
                <img className="erweima-item-img" src={item.erweima} alt="erweima" />
                <text className="erweima-item-text" >{item.title}</text>
              </div>
            ))
          }
        </div>
        <div className="index-footer-content-right-contact">联系我们：400-108-9915</div>
        <div className="index-footer-content-right-cooperation">商务合作：alluknows888@163.com</div>
      </div>
    </div>
    <div className="index-footer-copyright">
      <div>© 2022 河南省中视新科文化产业有限公司 版权所有</div>
      <div>
        <a style={{ marginRight: 12 }} href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41010702003150" target="_blank" rel="noopener noreferrer"><img src="/gongan.png" alt=""/>豫公网安备 41010702003150号</a>
        <a style={{ marginRight: 12 }} href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">豫ICP备12008746号-6</a>
        <a href="https://dxzhgl.miit.gov.cn/#/home" target="_blank" rel="noopener noreferrer">增值电信业务经营许可证：豫B2-20221432</a>
      </div>
    </div>
  </div>
)

export default IndexFooter;