import React from 'react';
import './index.scss';

const ABOUTUS_LIST = [{
  title: '长安链',
  desc: '基于国内领先的区块链服务平台TBaaS以及首个自主可控区块链技术长安链'
}, {
  title: '支付系统',
  desc: '采用国内权威支付系统——易宝支付、汇付天下，确保用户的资金安全与交易体验。'
}, {
  title: '河洛实验室',
  desc: '平台已和中科谱光郑州研究院、中科大数据研究院等联合成立“河洛实验室”，以发掘艺术品的数字化资产价值为研究方向，探索艺术品的数字版权登记模式和数字化传播场景。'
}]

const IndexAbout = () => (
  <div className="index-aboutus" id="liandong">
    <div className="index-aboutus-title">万物联动</div>
    <div className="index-aboutus-subtitle">Ingenuity</div>
    <div className="index-aboutus-list">
      {
        ABOUTUS_LIST.map(item => (
          <div className="index-aboutus-list-item">
            <div className="index-aboutus-list-item-title">{item.title}</div>
            <div className="index-aboutus-list-item-desc">{item.desc}</div>
          </div>
        ))
      }
    </div>
  </div>
)

export default IndexAbout;