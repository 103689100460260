/**
 * 万物匠心
 */
import React, { useState } from 'react';
import avatar_fmr from '@/assets/avatar_fmr.png';
import avatar_yxzm from '@/assets/avatar_yxzm.png';
import avatar_kw from '@/assets/avatar_kw.png';
import avatar_scbb from '@/assets/avatar_scbb.png';
import avatar_qh from '@/assets/avatar_qh.png';
import avatar_sj from '@/assets/avatar_sj.png';
import avatar_md from '@/assets/avatar_md.png';
import avatar_fq from '@/assets/avatar_fq.png';
import avatar_hm from '@/assets/avatar_hm.png';
import avatar_zm from '@/assets/avatar_zm.png';
import avatar_aim from '@/assets/avatar_aim.png';
import avatar_jg from '@/assets/avatar_jg.png';
import authors_icon from '@/assets/authors_icon.png';
import authors_arrow_r from '@/assets/authors_arrow_r.png';
import authors_arrow_rt from '@/assets/authors_arrow_rt.png';
import authors_arrow_l from '@/assets/authors_arrow_l.png';
import './index.scss';

const AUTHORS = [{
  id: 1,
  status: 'created',
  cnt: '6',
  author: '有仙则名',
  authorAvatar: avatar_yxzm,
  bgColor: 'rgba(30, 143, 166, 1)',
}, {
  id: 2,
  status: 'created',
  cnt: '3',
  author: '深圳飞梦人',
  authorAvatar: avatar_fmr,
  bgColor: 'rgba(10, 38, 62, 1)',
}, {
  id: 3,
  status: 'creating',
  author: '开雾OPENWIND',
  authorAvatar: avatar_kw,
  bgColor: 'rgba(151, 87, 215, 1)',
}, {
  id: 4,
  status: 'creating',
  author: '蔬菜帮帮',
  authorAvatar: avatar_scbb,
  bgColor: 'rgba(69, 178, 107, 1)',
}, {
  id: 5,
  status: 'creating',
  author: '清华大学',
  subAuthor: '文化创意研究院',
  authorAvatar: avatar_qh,
  bgColor: 'rgba(158, 158, 158, 1)',
}, {
  id: 6,
  status: 'creating',
  author: '上海视觉艺术学院',
  authorAvatar: avatar_sj,
  bgColor: 'rgba(30, 143, 166, 1)',
}, {
  id: 7,
  status: 'creating',
  author: '上海没顶文化',
  subAuthor: '投资有限公司',
  authorAvatar: avatar_md,
  bgColor: 'rgba(10, 38, 62, 1)',
}, {
  id: 8,
  status: 'creating',
  author: '梵趣文化',
  authorAvatar: avatar_fq,
  bgColor: 'rgba(151, 87, 215, 1)',
}, {
  id: 9,
  status: 'creating',
  author: '嘿嚒嘿嚒',
  authorAvatar: avatar_hm,
  bgColor: 'rgba(69, 178, 107, 1)',
}, {
  id: 10,
  status: 'creating',
  author: '宁波早木文化',
  subAuthor: '创意有限公司',
  authorAvatar: avatar_zm,
  bgColor: 'rgba(158, 158, 158, 1)',
}, {
  id: 11,
  status: 'creating',
  author: 'AIM国际设计',
  authorAvatar: avatar_aim,
  bgColor: 'rgba(30, 143, 166, 1)',
}, {
  id: 12,
  status: 'creating',
  author: '郑州九格数字图形',
  subAuthor: '设计有限公司',
  authorAvatar: avatar_jg,
  bgColor: 'rgba(10, 38, 62, 1)',
}];

const IndexOriginality = () => {
  const [index, setIndex] = useState(0);

  return (
    <div className="index-authors" id="jiangxin">
      <div className="index-authors-container">
        <div className="title">万物匠心</div>
        {
          index > 0
          ?
          <img className="arr_l" src={authors_arrow_l} onClick={() => setIndex(pre => pre - 1)} alt="pre" />
          : null
        }
        {
          index < 2
          ?
          <img className="arr_r" src={authors_arrow_r} onClick={() => setIndex(pre => pre + 1)} alt="next" />
          : null
        }
        <div className="list_container">
          <div className={`list_${index} list`}>
            {
              AUTHORS.map(item => (
                <div className="author" key={item.id}>
                  <div className="author-title">
                    <div className="author-title-status" style={{backgroundColor: item.bgColor}}>
                      <img className="author-title-status_icon" src={authors_icon} alt="icon" />
                      <div className="author-title-status_name">{item.status === 'created' ? '创作' : '创作中'}</div>
                      {item.status === 'created' ? (
                        <React.Fragment>
                          <div className="author-title-status_line">|</div>
                          <div className="author-title-status_cnt">{item.cnt}</div>
                        </React.Fragment>
                      ) : null}
                    </div>
                    <img className="author-title-arrow" src={authors_arrow_rt} alt="arrow" />
                  </div>
                  <img className="author-avator" src={item.authorAvatar} alt="avatar" />
                  <div className="author-name">{item.author}</div>
                  {item.subAuthor ? (<div className="author-name-sub">{item.subAuthor}</div>) : null}
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default IndexOriginality;