import React from 'react';
import energize from '@/assets/energize_illu.png';
import './index.scss';

const ENERGIZE = {
  title: '万物赋能',
  main: '1、活动对象：首期购买右边藏品的用户；\n2、权益内容：已购藏品对应的藏品卡，一份藏品对应一张卡片；\n9个藏品均购买的用户，额外获得一个卡册；\n3、活动周期：首发藏品开始到9个藏品售罄；\n4、权益发放时间：9款藏品均发售之后，陆续寄送；',
}

const IndexEnergize = () => (
  <div className="index-energize" id="funeng">
    <div className="index-energize-content">
      <div className="index-energize-content-title">{ENERGIZE.title}</div>
      <div className="index-energize-content-desc">{ENERGIZE.main}</div>
    </div>
    <img className="index-energize-pic" src={energize} alt="energize" />
  </div>
)

export default IndexEnergize;