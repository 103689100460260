import React from 'react';
import logo from '@/assets/logo.png';
import './index.scss';

const IndexHeader = () => (
  <div className="index-header">
    <div className="index-header-content">
      <div className="logo">
        <img src={logo} alt="banner" />
      </div>
      <a className="nav" href="#qiyuan">万物起源</a>
      <a className="nav" href="#zhidi">万物之地</a>
      <a className="nav" href="#jiangxin">万物匠心</a>
      <a className="nav" href="#huodong">万物活动</a>
      <a className="nav" href="#liandong">万物联动</a>
      <a className="nav" href="#funeng">万物赋能</a>
    </div>
  </div>
)

export default IndexHeader;