import React from 'react';
import { H5_URL } from '@/actions';
import title from '@/assets/title.png';
import './index.scss';

const IndexBanner = ({showmodal}) => (
  <div className="index-banner" id="qiyuan">
    <video className="index-banner-left" src="https://img.alluknows.com/home/video/zayg3aje.mp4" autoPlay controls muted loop >您的浏览器不支持video标签</video>
    <div className="index-banner-right">
      <img src={title} alt="行走河南，读懂中国" />
      <div className="index-banner-right-subtitle">
        <p>万物有数（alluknows)，是河南省文化旅游投资集团旗下中视新科公司推出的河南首家国资规范化数字艺术平台，通过数字技术全面梳理中华文化资源，推动文化资源科学分类和规范标识，努力实现中华文化数字化成果全民共享、优秀创新成果影响海内外。</p>
        <p>作为国资企业平台，中视新科始终以弘扬传统文化、彰显中华文化自信为己任，不断创新表现形态，让中华文化瑰宝活起来，助推“行走河南·读懂中国”品牌走向世界。</p>
      </div>
      <div className="index-banner-right-btn" onClick={() => showmodal(H5_URL)}>去探索</div>
    </div>
  </div>
)

export default IndexBanner;